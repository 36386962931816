import * as React from 'react';
import { Component } from 'react';
import * as styles from './EventPreview.module.scss';
import { ContentfulEventPreviewFragment } from '../../gatsby-queries';
import { UIEventDate } from '../../ui/UIEventDate';
import { UIEventCategoryListing } from '../../ui/UIEventCategoryListing';
import GatsbyLink from 'gatsby-link';
import { getPagePath } from '../../utils/URLHelper';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { graphql } from 'gatsby';
import { sanitizeUrl } from '../../utils/URLHelper';

export class EventPreview extends Component<{ className: string; data: ContentfulEventPreviewFragment }> {
	render() {
		return (
			<GatsbyLink to={getPagePath(sanitizeUrl(this.props.data.slug), 'event')} className={`module-margin-small ${styles.wrapper} ${this.props.className}`}>
				<div className={`text-style-h2 ${styles.titleWrapper}`}>
					<UIEventCategoryListing type={this.props.data.type} filterVerticalCategory={this.props.data.filterVerticalCategory} className={styles.itemInfo} />
					{ 
						//@ts-ignore:
						<UIEventDate startDate={this.props.data.startDate} endDate={this.props.data.endDate} type={this.props.data.__typename} className={styles.date} />
					}
					<h3 className={`text-style-h3 ${styles.title}`}>{this.props.data.title}</h3>
					{this.props.data.image && <GatsbyImageWrapper outerWrapperClassName={styles.imageWrapper} alt={this.props.data.title} fluid={this.props.data.image.sizes} />}
				</div>
			</GatsbyLink>
		);
	}
}

export const query = graphql`
	fragment ContentfulEventPreviewFragment on ContentfulEvent {
		contentful_id
		title
		startDate
		endDate
		slug
		image {
			file {
				url
			}
		}
		filterVerticalCategory {
			title
		}
		type {
			typeName
		}
		image {
			sizes(maxWidth: 960, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
	}
`;
