import * as React from 'react';
import { Component } from 'react';
import * as styles from './ModuleFeaturedPageLink.module.scss';
import { ContentfulModuleFeaturedPageLinkFragment } from '../../gatsby-queries';
import ViewableMonitor from '../ui/ViewableMonitor';
import GatsbyLinkExternalSupport from '../../ui/GatsbyLinkExternalSupport';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { graphql } from 'gatsby';

class ModuleFeaturedPageLink extends Component<{ data: ContentfulModuleFeaturedPageLinkFragment; asHighlightedItem?:boolean }> {
	render() {
		return (
			<div className={`module-margin w-100 ${styles.wrapper} ${this.props.asHighlightedItem === true ? styles.highlightedItem : ''} ${this.props.data.openInNewTab ? styles.externalLink : ''}`}>
				<GatsbyLinkExternalSupport to={this.props.data.link} openinnewtab={this.props.data.openInNewTab}>
					<div className={styles.innerWrapper}>
						<ViewableMonitor disabled={this.props.asHighlightedItem}>
							<div className={`${styles.textWrapper}`}>
								<div className={styles.textWrapperInner}>
									<div>
										{this.props.data.typeHeading && (
											<div className={`text-style-body ${styles.typeHeading}`} dangerouslySetInnerHTML={{ __html: this.props.data.typeHeading.childMarkdownRemark.html }} />
										)}
									</div>
									{this.props.asHighlightedItem && <GatsbyImageWrapper alt={this.props.data.titleText.titleText} fluid={this.props.data.image.imageHighlighted} />}
									<div>
										<div className={styles.titleDetailWrapper}>
											<h3 className={`text-style-h3 ${styles.title}`}>{this.props.data.titleText.titleText}</h3>
											{this.props.data.description && (
												<div className={`text-style-body ${styles.description}`} dangerouslySetInnerHTML={{ __html: this.props.data.description.childMarkdownRemark.html }} />
											)}
										</div>
									</div>
								</div>
								<span className={`text-style-link-1 ${styles.ctaLink}`} tabIndex={0}>
									{this.props.data.linkText}
								</span>
							</div>
						</ViewableMonitor>
						{!this.props.asHighlightedItem && <ViewableMonitor delay={true}>
							<div className={styles.imageWrapper}>
								<GatsbyImageWrapper alt={this.props.data.titleText.titleText} fluid={this.props.data.image.image} />
							</div>
						</ViewableMonitor>}
					</div>
				</GatsbyLinkExternalSupport>
			</div>
		);
	}
}

export default ModuleFeaturedPageLink;

export const query = graphql`
	fragment ContentfulModuleFeaturedPageLinkFragment on ContentfulModuleFeaturedPageLink {
		id
		typeHeading {
			childMarkdownRemark {
				html
			}
		}
		description {
			childMarkdownRemark {
				html
			}
		}
		titleText {
			titleText
		}
		linkText
		link
		image {
			image: sizes(maxWidth: 680, maxHeight: 874, resizingBehavior: FILL, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
			imageHighlighted: sizes(maxWidth: 900, maxHeight: 560, resizingBehavior: FILL, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
		openInNewTab
	}
`;
