import * as React from 'react';
import * as styles from './ModuleRichBodyText.module.scss';
import { Component } from 'react';
import { ContentfulModuleRichBodyTextFragment } from '../../gatsby-queries';
import ViewableMonitor from '../ui/ViewableMonitor';
import { graphql } from 'gatsby';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ModuleQuote from './ModuleQuote';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import ModuleStatistics from './ModuleStatistics';
const options = {
	renderNode: {
		[BLOCKS.EMBEDDED_ENTRY]: (node) => {
			if (node.data.target.__typename === 'ContentfulModuleQuote') {
				return <ModuleQuote key={node.data.target.contentful_id} inline={true} inlineFullWidth={true} data={node.data.target} />;
			} else if (node.data.target.__typename === 'ContentfulModuleStatisticS') {
				return <ModuleStatistics key={node.data.target.contentful_id} inline={false} inlineFullWidth={true} data={node.data.target} />;
			} else if (node.data.target.__typename === 'ContentfulMediaImageGalleryItem') {
				return (<div className={styles.imageWrapper + ' ' + styles.imageWrapperInline + ' ' + styles.fullWidth}>
					{
						// @ts-ignore
						<GatsbyImageWrapper alt={node.data.target.image.title} outerWrapperClassName={styles.desktopImage} fluid={node.data.target.image.desktop} />
					}
					{
						// @ts-ignore
						<GatsbyImageWrapper alt={node.data.target.image.title} outerWrapperClassName={styles.mobileImage} fluid={node.data.target.image.mobile} />
					}
					{node.data.target.caption && <div className={`text-style-caption ${styles.caption}`} dangerouslySetInnerHTML={{__html: node.data.target.caption.childMarkdownRemark.html}} />}
				</div>);
			}
			// const { title, description } = node.data.target.fields;
			return <div />
		},
		[INLINES.EMBEDDED_ENTRY]: (node) => {
			if (node.data.target.__typename === 'ContentfulModuleQuote') {
				return <ModuleQuote key={node.data.target.contentful_id} inline={true} data={node.data.target} />;
			}
			else if (node.data.target.__typename === 'ContentfulModuleStatisticS') {
				return <ModuleStatistics key={node.data.target.contentful_id} inline={true} data={node.data.target} />;
			}
			else if (node.data.target.__typename === 'ContentfulMediaImageGalleryItem') {
				return (<div className={styles.imageWrapper + ' ' + styles.imageWrapperInline}>
					{
						// @ts-ignore
						<GatsbyImageWrapper alt={node.data.target.image.title} outerWrapperClassName={styles.desktopImage} fluid={node.data.target.image.desktop} />
					}
					{
						// @ts-ignore
						<GatsbyImageWrapper alt={node.data.target.image.title} outerWrapperClassName={styles.mobileImage} fluid={node.data.target.image.mobile} />
					}
					{node.data.target.caption && <div className={`text-style-caption ${styles.caption}`} dangerouslySetInnerHTML={{__html: node.data.target.caption.childMarkdownRemark.html}} />}
				</div>);
			}
			// const { title, description } = node.data.target.fields;
			return <div />
		},
		[BLOCKS.EMBEDDED_ASSET]: (node) => {
			return (<div className={styles.imageWrapper}>
				{
					// @ts-ignore
					<GatsbyImageWrapper alt={node.data.target.title} outerWrapperClassName={styles.desktopImage} fluid={node.data.target.desktop} />
				}
				{
					// @ts-ignore
					<GatsbyImageWrapper alt={node.data.target.title} outerWrapperClassName={styles.mobileImage} fluid={node.data.target.mobile} />
				}
				{node.data.target.title && <div className={`text-style-caption ${styles.caption}`}>{node.data.target.title}</div>}
			</div>);
		}
	}
};
class ModuleRichBodyText extends Component<{ data: ContentfulModuleRichBodyTextFragment }> {



	render() {
		if (this.props.data.bodyText) {
			return (
				<ViewableMonitor>
					{this.props.data.bodyText.raw && (
						<div className={`module-margin text-style-body-module ${styles.wrapper}`}>
							{renderRichText(this.props.data.bodyText, options)}
						</div>
					)}
				</ViewableMonitor>
			);
		} else {
			return <span />;
		}
	}
}

export default ModuleRichBodyText;

export const query = graphql`
	fragment ContentfulModuleRichBodyTextFragment on ContentfulModuleRichBodyText {
		id
		bodyText {
			raw
			references {
				... on ContentfulModuleQuote {
					# contentful_id is required to resolve the references
					contentful_id
					__typename
					...ContentfulModuleQuoteFragment
				}
				... on ContentfulMediaImageGalleryItem {
					# contentful_id is required to resolve the references
					contentful_id
					__typename
					image {
						title
						desktop: sizes(maxHeight: 544, maxWidth: 840, resizingBehavior: FILL, quality: 85) {
							...GatsbyContentfulSizes_withWebp_noBase64
						}
						mobile: sizes(maxHeight: 450, maxWidth: 640, resizingBehavior: FILL, quality: 85) {
							...GatsbyContentfulSizes_withWebp_noBase64
						}
					}
					caption {
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulModuleStatisticS {
					contentful_id
					__typename
					subtitle {
						childMarkdownRemark {
							html
						}
					}
					expandedStatsTitle {
						expandedStatsTitle
					}
					statisticCharts {
						displayStyle
						displayAtHalfColumnWidth
						values {
							value
							valueLabel
							identifier
							highlighted
						}
					}
				}
				... on ContentfulAsset {
					# contentful_id is required to resolve the references
					contentful_id
					__typename
					title
					desktop: sizes(maxHeight: 544, maxWidth: 840, resizingBehavior: FILL, quality: 85) {
						...GatsbyContentfulSizes_withWebp_noBase64
					}
					mobile: sizes(maxHeight: 450, maxWidth: 640, resizingBehavior: FILL, quality: 85) {
						...GatsbyContentfulSizes_withWebp_noBase64
					}
				}
 		    }
		}
	}
`;
