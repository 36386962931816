import * as React from 'react';
import { Component } from 'react';
import * as styles from './Module2FeaturedPageLinks.module.scss';
import { ContentfulModule2FeaturedPageLinksFragment } from '../../gatsby-queries';
import GatsbyLinkExternalSupport from '../../ui/GatsbyLinkExternalSupport';
import ViewableMonitor from '../ui/ViewableMonitor';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { graphql } from 'gatsby';

class Module2FeaturedPageLinks extends Component<{ data: ContentfulModule2FeaturedPageLinksFragment }> {
	render() {
		return (
			<div className={`module-margin ${styles.wrapper}`}>
				<ViewableMonitor>
					<div className={styles.imageWrapper}>
						<GatsbyLinkExternalSupport to={this.props.data.firstLink}>
							<GatsbyImageWrapper alt={this.props.data.firstTitle} fluid={this.props.data.firstImage.sizes} />
							<h2 className={`text-style-h2 ${styles.title}`}>{this.props.data.firstTitle}</h2>
							<p className={`${styles.description}`}>{this.props.data.firstDescription.firstDescription}</p>
							<span className={styles.ctaLink} tabIndex={0}>
								<span>{this.props.data.firstLinkText}</span>
							</span>
						</GatsbyLinkExternalSupport>
					</div>
				</ViewableMonitor>
				<ViewableMonitor delay={true}>
					<div className={styles.imageWrapper}>
						<GatsbyLinkExternalSupport to={this.props.data.secondLink}>
							<GatsbyImageWrapper alt={this.props.data.secondTitle} fluid={this.props.data.secondImage.sizes} />
							<h2 className={`text-style-h2 ${styles.title}`}>{this.props.data.secondTitle}</h2>
							<p className={`${styles.description}`}>{this.props.data.secondDescription.secondDescription}</p>
							<span className={styles.ctaLink} tabIndex={0}>
								<span>{this.props.data.secondLinkText}</span>
							</span>
						</GatsbyLinkExternalSupport>
					</div>
				</ViewableMonitor>
			</div>
		);
	}
}

export default Module2FeaturedPageLinks;

export const query = graphql`
	fragment ContentfulModule2FeaturedPageLinksFragment on ContentfulModule2FeaturedPageLinks {
		id
		firstImage {
			sizes(maxHeight: 328, maxWidth: 500, resizingBehavior: FILL, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
		secondImage {
			sizes(maxHeight: 328, maxWidth: 500, resizingBehavior: FILL, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
		firstTitle
		firstDescription {
			firstDescription
		}
		firstLinkText
		firstLink
		secondTitle
		secondDescription {
			secondDescription
		}
		secondLinkText
		secondLink
	}
`;
