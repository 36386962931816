import * as React from 'react';
import { Component } from 'react';
import * as styles from './ArticlePreview.module.scss';
import { ContentfulPageArticlePreviewFragment } from '../../gatsby-queries';
// import { UIEventCategoryListing } from '../../ui/UIEventCategoryListing';
import { getPagePath } from '../../utils/URLHelper';
import GatsbyLink from 'gatsby-link';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

export class ArticlePreview extends Component<{ portraitMode?: boolean; className?: string; placeHolderImage?: any; data: ContentfulPageArticlePreviewFragment }> {

	render() {
		const previewImage = this.props.data.teaserImage ? this.props.data.teaserImage : this.props.data.heroImage;
		const articleImage = previewImage && <GatsbyImageWrapper outerWrapperClassName={styles.imageWrapper} fluid={previewImage.sizes} />;
		// const articleCategory = <UIEventCategoryListing key={`category-${this.props.key}`} type={'Article'} filterVerticalCategory={this.props.data.filterVerticalCategory} className={styles.itemInfo} />
		let defaultClass = 'module-margin-small';
		if (this.props.portraitMode) {
			defaultClass = 'portraitMode';
		}
		return (
			<div className={`${defaultClass} ${styles.wrapper} ${this.props.data.contentful_id}`}>
				<GatsbyLink to={getPagePath(this.props.data.slug, 'article')} className={`${styles.titleWrapper}`}>
					{articleImage}
					<h3 className={`text-style-h3 ${styles.title}`}>{this.props.data.title}</h3>
					{this.props.data.opEdCreditOptional &&
					(<div className={styles.creditsProfile}>{this.props.data.opEdCreditOptional.profilePhoto &&
					<GatsbyImageWrapper alt={this.props.data.opEdCreditOptional.profilePhoto.title} outerWrapperClassName={styles.creditImage}
										fluid={this.props.data.opEdCreditOptional.profilePhoto.thumb} />}
						<span className={styles.creditsName}>{this.props.data.opEdCreditOptional.name.name}</span>
					</div>)}
					{this.props.data.contentTypePreviewInfo &&
					<span className={styles.extraInfo + (this.props.data.contentType?.slug === 'video' ? ' ' + styles.video : '')}>{this.props.data.contentTypePreviewInfo}</span>}
				</GatsbyLink>
				<div className={styles.itemInfo}>
					<span className={styles.category}>{(this.props.data.contentType ? <a href={getPagePath(this.props.data.contentType?.slug, 'article')}>{this.props.data.contentType.title}</a> : <FormattedMessage id={'Article'} />)}</span><FormattedMessage id={'in'} />
					{this.props.data.filterArticleTags?.map((tag, index) => {
						return <a className={styles.tagLink} href={getPagePath(tag.slug, 'article')} key={index}>{tag.title}</a>;
					})}
				</div>
			</div>
		);
	}
}

export const query = graphql`
	fragment ContentfulPageArticlePreviewFragment on ContentfulPageArticle {
		contentful_id
		title
		date
		slug
		heroImage {
			file {
				url
			}
		}
		filterVerticalCategory {
			title
			slug
		}
		opEdCreditOptional {
			name {
				name
			}
			title {
				title
			}
			profilePhoto {
				title
				thumb: sizes(maxWidth: 100, maxHeight: 100, resizingBehavior: FILL, quality: 85) {
					...GatsbyContentfulSizes_withWebp_noBase64
				}
			}
		}
		contentTypePreviewInfo
		contentType {
			title
			slug
		}
		filterArticleTags {
			title
			slug
		}
		heroImage {
			sizes(maxWidth: 960, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
		teaserImage {
			sizes(maxWidth: 320, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
	}
`;
