import * as React from 'react';
import * as styles from './ModuleHeadingSummaryWithButtons.module.scss';
import { Component } from 'react';
import { ContentfulModuleHeadingSummaryWithButtonsFragment } from '../../gatsby-queries';
import ViewableMonitor from '../ui/ViewableMonitor';
import { graphql } from 'gatsby';
import ModuleCtaLink from './ModuleCtaLink';

class ModuleHeadingSummaryWithButtons extends Component<{ data: ContentfulModuleHeadingSummaryWithButtonsFragment }> {

	render() {
		return (
			<div className={`module-margin ${styles.wrapper} ${this.props.data.darkBackground ? styles.darkBackground : ''}`}>
				<ViewableMonitor>
					<div className={styles.shortBoxedWrapper}>
						<div className={styles.introParagraph}>
							<p className={styles.title}>{this.props.data.moduleHeadingSummaryWithButtonsTitle.moduleHeadingSummaryWithButtonsTitle}</p>
							<div className={styles.introParagraphText} dangerouslySetInnerHTML={{ __html: this.props.data.description.childMarkdownRemark.html }} />
						</div>
						<div className={styles.links}>
							{this.props.data.links.map((link, index) => <ModuleCtaLink darkBackground={this.props.data.darkBackground} inline={true} data={link} key={link.id + index} />)}
						</div>
					</div>
				</ViewableMonitor>
			</div>
		);
	}
}

export default ModuleHeadingSummaryWithButtons;

export const query = graphql`
	fragment ContentfulModuleHeadingSummaryWithButtonsFragment on ContentfulModuleHeadingSummaryWithButtons {
		id
		moduleHeadingSummaryWithButtonsTitle {
			moduleHeadingSummaryWithButtonsTitle
		}
		description {
			childMarkdownRemark {
				html
			}
		}
		darkBackground
		links {
			...ContentfulModuleCtaLinkFragment
		}
	}
`;
