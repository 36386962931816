import * as React from 'react';
import * as styles from './ModuleHighlightedItems.module.scss';
import { Component } from 'react';
import { ContentfulModuleHighlightedItemsFragment } from '../../gatsby-queries';
import { EventPreview } from '../Previews/EventPreview';
import { ArticlePreview } from '../Previews/ArticlePreview';
import ViewableMonitor from '../ui/ViewableMonitor';
import Masonry from 'react-masonry-component';
import { graphql } from 'gatsby';
import ScrollList from '../ScrollList';
import ModuleFeaturedPageLink from './ModuleFeaturedPageLink';
import { Globals } from '../../utils/Globals';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { getPagePath } from '../../utils/URLHelper';
import ModuleCtaLink from './ModuleCtaLink';

export class ModuleHighlightedItems extends Component<{ data: ContentfulModuleHighlightedItemsFragment, useScrollList?: boolean }> {
	render() {
		const animationDirection = Globals.CURRENT_LANGUAGE_PREFIX === 'ar/' ? -1 : 1;

		if (this.props.data.showLatestFromCategory) {
			this.props.data.highlightedItems = this.props.data.showLatestFromCategory.pageArticles as any;
		}
		if (this.props.data.useMasonryLayout) {
			const masonryOptions = {
				transitionDuration: 0,
				resize: true,
				horizontalOrder: true,
				percentPosition: true,
				gutter: 50
			};

			return (
				<div className={`module-margin ${styles.wrapper} ${styles.masonryLayoutCropped}`}>
					<div>
						<ViewableMonitor>
							<h2 tabIndex={0} className="text-style-category-headline">{this.props.data.headline.title}</h2>
						</ViewableMonitor>
					</div>
					<Masonry
						className={styles.itemsWrapper + ' ' + styles.masonryLayout} // default ''
						disableImagesLoaded={true} // default false
						options={masonryOptions}
						updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
					>
						{this.props.data.highlightedItems.map((item, index) => {
							const id = module.id + index;
							switch (item.__typename) {
								case 'ContentfulEvent':
									return (
										<ViewableMonitor delay={index + 1} key={id}>
											<EventPreview className={this.props.data.highlightedItems.length > 4 && index > 1 ? 'small' : ''} data={item} />
										</ViewableMonitor>
									);
								case 'ContentfulPageArticle':
									return (
										<ViewableMonitor delay={index + 1} key={id}>
											<ArticlePreview className={this.props.data.highlightedItems.length > 4 && index > 1 ? 'small' : ''} data={item} />
										</ViewableMonitor>
									);
							}
						})}
					</Masonry>
				</div>
			);
		} else if (this.props.data.highlightedItems) {
			const wrapperClass = this.props.data.highlightedItems.length > 5 ? styles.wrapperMoreThan5 : styles['style' + (this.props.data.showLatestFromCategory ? 'Latest' + this.props.data.highlightedItems.length : this.props.data.highlightedItems.length)];
			let featuredPageLinkItems = [];
			let fitlerArticleTags = [];
			this.props.data.highlightedItems.forEach((item) => {
				if (item.__typename === 'ContentfulModuleFeaturedPageLink') {
					featuredPageLinkItems.push(item);
				}
				if (item.__typename === 'ContentfulFilterArticleTag') {
					// @ts-ignore
					if(item.slug !== 'news') {
						fitlerArticleTags.push(item);
					}
				}
			});
			if (featuredPageLinkItems.length > 0) {
				return (<div className={`${styles.wrapper} module-margin`}>
					<h2 tabIndex={0} className={styles.scrollerHeadline}>
						{this.props.data.headline.title}
					</h2>
					<ScrollList showPaginator={true} animationDirection={animationDirection} listClassName={'module-margin-small'} className={'highlightedScroller'}>
						{
							// @ts-ignore
							featuredPageLinkItems.map((edge, index) => {
								// @ts-ignore
								return (<ViewableMonitor disabled={(index >= 3)} key={edge.id + index} delay={index + 1}>
										{
											// @ts-ignore
											<ModuleFeaturedPageLink data={edge} asHighlightedItem={true} />
										}
									</ViewableMonitor>
								);
							})
						}
					</ScrollList>
				</div>);
			} else if (fitlerArticleTags.length > 0) {
				return (<div className={`${styles.wrapper} ${styles.articleTagsWrapper}`}>
					<ScrollList showPaginator={true} animationDirection={animationDirection} listClassName={'module-margin-small'} className={'highlightedScroller'}>
						{
							// @ts-ignore
							fitlerArticleTags
							// @ts-ignore
							.sort(function(a, b) {
								// @ts-ignore
								return b.sortOrder - a.sortOrder;
							}).map((edge, index) => {
								return (<ViewableMonitor disabled={(index >= 6)} key={edge.id + index} delay={index + 1}>
										{
											// @ts-ignore
											<a href={getPagePath(edge.slug, 'article')} className={styles.articleTag}>
												{edge.image && <GatsbyImageWrapper alt={edge.image.title} outerWrapperClassName={styles.image} fluid={edge.image.sizes} />}
												<span>{edge.title}</span>
											</a>
										}
									</ViewableMonitor>
								);
							})
						}
					</ScrollList>
				</div>);
			} else if (this.props.useScrollList) {
				return (<div className={`${styles.wrapper} ${styles.relatedArticlesWrapper}`}>
					<ScrollList showPaginator={true} animationDirection={animationDirection} listClassName={'module-margin-small'} className={'highlightedScroller'}>
						{this.props.data.highlightedItems.map((item, index) => {
							const id = module.id + index;
							switch (item.__typename) {
								case 'ContentfulEvent':
									return (
										<ViewableMonitor delay={index + 1} key={id}>
											<EventPreview className={this.props.data.highlightedItems.length > 4 && index > 1 ? 'small' : ''} data={item} />
										</ViewableMonitor>
									);
								case 'ContentfulPageArticle':
									return (
										<ViewableMonitor delay={index + 1} key={id}>
											<ArticlePreview className={(this.props.data.highlightedItems.length || this.props.data.showLatestFromCategory) > 4 && index > 1 ? 'small' : ''}
															data={item} />
										</ViewableMonitor>
									);
							}
						})}
					</ScrollList>
				</div>);
			} else {
				return (
					<div
						className={`module-margin ${styles.wrapper} ${this.props.data.showLatestFromCategory ? styles.showLatest : ''} ${this.props.data.highlightedBackground ? styles.highlightedBackground : ''}`}>
						<div>
							<ViewableMonitor>
								<h2 tabIndex={0} className="text-style-category-headline">
									{this.props.data.headline.title}
									{/* ({this.props.data.highlightedItems.length})*/}
								</h2>
							</ViewableMonitor>
						</div>
						<div className={styles.itemsWrapper + ' ' + wrapperClass}>
							{this.props.data.highlightedItems.map((item, index) => {
								const id = module.id + index;
								switch (item.__typename) {
									case 'ContentfulEvent':
										return (
											<ViewableMonitor delay={index + 1} key={id}>
												<EventPreview className={this.props.data.highlightedItems.length > 4 && index > 1 ? 'small' : ''} data={item} />
											</ViewableMonitor>
										);
									case 'ContentfulPageArticle':
										return (
											<ViewableMonitor delay={index + 1} key={id}>
												<ArticlePreview className={(this.props.data.highlightedItems.length || this.props.data.showLatestFromCategory) > 4 && index > 1 ? 'small' : ''}
																data={item} />
											</ViewableMonitor>
										);
								}
							})}
						</div>
						{this.props.data.readMoreCta && <ModuleCtaLink data={this.props.data.readMoreCta} />}
					</div>
				);
			}
		} else {
			return <div>{this.props.data.showLatestFromCategory?.title}{this.props.data.showLatestFromCategory?.pageArticles.length}</div>;
		}
	}
}

export const query = graphql`
	fragment ContentfulModuleHighlightedItemsFragment on ContentfulModuleHighlightedItems {
		id
		headline: title {
			title
		}
		sortByDate
		useMasonryLayout
		highlightedItems {
			__typename
			...ContentfulEventPreviewFragment
			...ContentfulPageArticlePreviewFragment
			...ContentfulModuleFeaturedPageLinkFragment
			... on ContentfulFilterArticleTag {
				title
				id
				slug
				image {
					title
					sizes(maxWidth: 400, maxHeight: 230, resizingBehavior: FILL, quality: 85) {
						...GatsbyContentfulSizes_withWebp_noBase64
					}
				}
				sortOrder
			}
		}
		highlightedBackground
		readMoreCta {
			...ContentfulModuleCtaLinkFragment
		}
		showLatestFromCategory {
			... on ContentfulFilterArticleTag {
				id
				title
				pageArticles(limit: 4) {
					__typename
					...ContentfulPageArticlePreviewFragment
				}
			}
			... on ContentfulFilterArticleType {
				id
				title
				pageArticles(limit: 4) {
					__typename
					...ContentfulPageArticlePreviewFragment
				}
			}
		}
	}
`;
