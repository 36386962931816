import * as React from 'react';
import * as styles from './ModuleMediaNumberedTextList.module.scss';
import { Component } from 'react';
import { ContentfulModuleMediaNumberedTextListFragment } from '../../gatsby-queries';
import ViewableMonitor from '../ui/ViewableMonitor';
import { graphql } from 'gatsby';
import ModuleVideoYouTube from './ModuleVideoYouTube';
import ModuleVideoEmbedded from './ModuleVideoEmbedded';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';

const initialState = { activeIndex: 0 };
type State = Readonly<typeof initialState>;

class ModuleMediaNumberedTextList extends Component<{ data: ContentfulModuleMediaNumberedTextListFragment }, State> {
	readonly state: State = initialState;
	private timer = null;

	componentDidMount() {
		this.startLoop();
	}

	startLoop = () => {
		this.timer = setInterval(() => {
			this.setState({ activeIndex: this.state.activeIndex + 1 >= this.props.data.listItems.length ? 0 : this.state.activeIndex + 1 });
		}, 3000);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	render() {
		if (this.props.data.mediaNumberedTextListTitle) {
			return (
				<ViewableMonitor>
					<div className={'module-margin'}>
						{this.props.data.mediaNumberedTextListTitle.childMarkdownRemark && (
							<div className={`text-style-quote`}
								 dangerouslySetInnerHTML={{ __html: this.props.data.mediaNumberedTextListTitle.childMarkdownRemark.html }} />
						)}
						<div className={styles.wrapper}>
							{this.props.data.video && (this.props.data.video.__typename === 'ContentfulModuleVideoYouTube' ? <ModuleVideoYouTube data={this.props.data.video} /> :
								<ModuleVideoEmbedded data={this.props.data.video} />)}
							{!this.props.data.video && <GatsbyImageWrapper alt={this.props.data.image.title} outerWrapperClassName={styles.image} fluid={this.props.data.image.sizes} />}
							<div className={styles.listWrapper}>
								{this.props.data.listItems.map((item, index) => {
									return <div className={styles.textWrapper + (index === this.state.activeIndex ? ' ' + styles.active : '')} key={item.id + index}>
										<span className={styles.sectionNumber}>0{(index + 1)}</span>
										<div className={styles.descWrapper}>
											<h4>{item.title}</h4>
											<div className={styles.desc} dangerouslySetInnerHTML={{ __html: item.introductionText.childMarkdownRemark.html }} />
										</div>
									</div>;
								})}
							</div>
						</div>
					</div>
				</ViewableMonitor>
			);
		} else {
			return <span />;
		}
	}
}

export default ModuleMediaNumberedTextList;

export const query = graphql`
	fragment ContentfulModuleMediaNumberedTextListFragment on ContentfulModuleMediaNumberedTextList {
		id
		mediaNumberedTextListTitle {
			childMarkdownRemark {
				html
			}
		}
		video {
			... on ContentfulModuleVideoYouTube {
				__typename
				...ContentfulModuleVideoYouTubeFragment
			}
			... on ContentfulModuleVideoEmbedded {
				__typename
				...ContentfulModuleVideoEmbeddedFragment
			}
		}
		image {
			title
			sizes(maxWidth: 960, maxHeight: 540, resizingBehavior: FILL, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
		listItems {
			...ContentfulModuleSectionIntroductionFragment
		}
	}
`;
