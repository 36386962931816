import * as React from 'react';
import { Component } from 'react';
import * as styles from './ModuleHomeNewsletter.module.scss';
import ViewableMonitor from '../ui/ViewableMonitor';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
// @ts-ignore
import { moduleHomeNewsletterQuery } from '../gatsby-queries';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import MainMenuSocialCopyright from '../MainMenu/MainMenuSocialCopyright';
import axios from 'axios';

class ModuleHomeNewsletter extends Component<{ currLang?: any; images: moduleHomeNewsletterQuery } & WrappedComponentProps> {
	state = {
		email: null
	};

	convertNewLines(_text) {
		let lines = _text.toString().split('\n');
		let elements = [];
		for (let i = 0; i < lines.length; i++) {
			elements.push(lines[i]);
			if (i < lines.length - 1) {
				elements.push(<br key={i} />);
			}
		}
		return elements;
	}

	_handleChange = e => {
		this.setState({
			[`${e.target.name}`]: e.target.value
		});
	};

	_handleSubmit = e => {
		e.preventDefault();
		let validationField = document.getElementById('validationError')
		let successField = document.getElementById('success')
		let newsletter = document.getElementById('newsletter');
		if(this.state.email === null || this.state.email === '') {
			validationField.textContent = this.props.intl.formatMessage({ id: 'email_validation' })
			validationField.style.display = 'block'
			return
		}
		let body = {
			"contacts": [
				{
					"channels": [
						{
							"type": "email",
							"value": this.state.email
						}
					],
					"groups": [
						{
							"id": "168939913",
							"name": "Monthly Newsletter"
						}
					]
				}
			],
			"dedupeOn": "email",
			"groupNames": [
				"Monthly Newsletter"
			]
		}
		
		axios({
			method: "POST",
			url: "https://esputnik.com/api/v1/contacts",
			withCredentials: true,
			auth: {
				username: process.env.GATSBY_ESPUTNIK_USERNAME,
				password: process.env.GATSBY_ESPUTNIK_PASSWORD
			},
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			data: body
		})
		.then(() => {
			newsletter.style.display = 'none'
			validationField.style.display = 'none'
			successField.style.display = 'block'
		})
		.catch(() => {
			validationField.textContent = this.props.intl.formatMessage({ id: 'sign_up_newsletter_error' })
			validationField.style.display = 'block'
		});
		setTimeout(function() {
			document.getElementById('validationError').focus();
		}, 500);
	};

	render() {
		return (
			<div className={`${styles.bgGrey}`} id="moduleHomeNewsletter">
				<div className={`module-margin ${styles.bgGrey}`}>
					<div className={`${styles.innerWrapper}`}>
						<div className={`${styles.wrapper}`}>
							<ViewableMonitor>
								<div className={`text-style-body ${styles.textWrapper}`}>
									<div className={`text-style-body ${styles.nsWrapper}`}>
										<div>
											<h2 className={`text-style-h2 ${styles.title}`}>
												<FormattedMessage id={'stay_in_touch'} />
											</h2>
										</div>
										<div className={`text-style-h2 ${styles.description}`}>
											<FormattedMessage id={'follow_us_text'} />
											<MainMenuSocialCopyright connectModule={true} />
										</div>
										<div className={`${styles.grid}`}>
											<h2 className={`text-style-h2 ${styles.description}`}>
												<FormattedMessage id="sign_up_newsletter">{txt => <>{this.convertNewLines(txt)} </>}</FormattedMessage>
											</h2>
											<form id="newsletter" onSubmit={this._handleSubmit} className={styles.newsletter} title={'Newslettersignupform'} aria-label={'Newslettersignupform'}>
												{
													<>
														{
															// @ts-ignore
															<label className={styles.newslettervisuallyhidden}>Email </label>
														}
														<span>
															<input
																aria-label={'Newslettersignup'}
																title={'Newslettersignupemail'}
																onChange={this._handleChange}
																placeholder={this.props.intl.formatMessage({ id: 'email' })}
																className={styles.st_newsletter_input}
																type="email"
																name="email"
																id="Newslettersignupemail"
															/>
														</span>
														<input
															type="submit"
															aria-label={'Newslettersignup'}
															title={'Newslettersignup'}
															className={styles.newsletter_button}
															name={'Newslettersignupsubmit'}
														/>
													</>
												}
											</form>
											<span className={styles.validationError} id="validationError" tabIndex={0}>
											</span>
											<span className={styles.success} id="success" tabIndex={0}>
												<FormattedMessage id={'sign_up_newsletter_success'} />
											</span>
										</div>
									</div>
								</div>
							</ViewableMonitor>
							<ViewableMonitor>
								<div className={`${styles.title} ${styles.detailsWrapper}`}>
									{
										// @ts-ignore
										<GatsbyImageWrapper fluid={this.props.image.allContentfulAsset.edges[0]?.node.sizes} />
									}
								</div>
							</ViewableMonitor>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default injectIntl(props => (
	<StaticQuery
		query={graphql`
			query moduleHomeNewsletterQuery {
				allContentfulAsset(filter: { title: { in: ["qfhome-connect-with-us-v2"] }, node_locale: { eq: "en-US" } }) {
					edges {
						node {
							title
							sizes(maxWidth: 960, quality: 85) {
								...GatsbyContentfulSizes_withWebp_noBase64
							}
						}
					}
				}
			}
		`}
		// @ts-ignore
		render={data => <ModuleHomeNewsletter image={data as ModuleHomeNewsletterQuery} {...props} />}
	/>
));
