import * as React from 'react';
import { Component } from 'react';
import * as styles from './ModuleImageBody.module.scss';
import { ContentfulModuleImageBodyFragment } from '../../gatsby-queries';
import ViewableMonitor from '../ui/ViewableMonitor';
import { GatsbyImageWrapper } from '../ui/GatsbyImageWrapper';
import { graphql } from 'gatsby';

class ModuleImageBody extends Component<{ data: ContentfulModuleImageBodyFragment }> {
	render() {
		return (
			<ViewableMonitor>
				<div className={`module-margin ${styles.wrapper}`}>
					<div className={`text-style-body ${styles.textWrapper}`} dangerouslySetInnerHTML={{ __html: this.props.data.text.childMarkdownRemark.html }} />
					<div className={styles.imageWrapper}>
						{
						// @ts-ignore
						<GatsbyImageWrapper alt={this.props.data.image.title} outerWrapperClassName={styles.desktopImage} fluid={this.props.data.image.desktop} />
						}
						{
						// @ts-ignore
						<GatsbyImageWrapper alt={this.props.data.image.title} outerWrapperClassName={styles.mobileImage} fluid={this.props.data.image.mobile} />
						}
						{this.props.data.imageCaptionOptional && <div className={`text-style-caption ${styles.caption}`}>{this.props.data.imageCaptionOptional}</div>}
					</div>
				</div>
			</ViewableMonitor>
		);
	}
}

export default ModuleImageBody;

export const query = graphql`
	fragment ContentfulModuleImageBodyFragment on ContentfulModuleImageBody {
		id
		imageCaptionOptional
		text {
			childMarkdownRemark {
				html
			}
		}
		image {
			title
			desktop: sizes(maxHeight: 544, maxWidth: 840, resizingBehavior: FILL, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
			mobile: sizes(maxHeight: 450, maxWidth: 640, resizingBehavior: FILL, quality: 85) {
				...GatsbyContentfulSizes_withWebp_noBase64
			}
		}
	}
`;
