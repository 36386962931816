import * as React from 'react';
import { Component } from 'react';
import * as styles from './ModuleTabContainer.module.scss';
import { ContentfulEventPreviewFragment, ContentfulModuleTabContainerFragment, ContentfulModuleWrapperFragment, PageVerticalQuery_allContentfulPageProgram } from '../../gatsby-queries';
import ModulesWrapper from '../ModulesWrapper';
import { Power1, Sine, TweenMax } from 'gsap/dist/gsap.min'
import ViewableMonitor from '../ui/ViewableMonitor';
import { graphql } from 'gatsby';
import $ from 'jquery/dist/jquery.min';




const initialState = { selectedIndex: 0 };
type State = Readonly<typeof initialState>;

class ModuleTabContainer extends Component<
	{
		data: ContentfulModuleTabContainerFragment;
		upcomingEventsData: ContentfulEventPreviewFragment[];
		programsListData?: PageVerticalQuery_allContentfulPageProgram;
		languageCode: 'en-US' | 'ar-QA';
	},
	State
> {
	readonly state: State = initialState;

	private tabContainerWrapper: HTMLDivElement;
	private tabContainers: HTMLDivElement[] = [];

	private clickedTabItem = event => {
		$(window).trigger('resize');
		if (event.key === 'Enter' || event.type === 'click') {

			if (event) {
				event.preventDefault();
				const newIndex = parseInt(event.target.dataset.index);
				
				if (this.state.selectedIndex !== newIndex) {
					TweenMax.set(this.tabContainerWrapper, { height: this.tabContainerWrapper.clientHeight });

					const oldIndex = this.state.selectedIndex;
					const newTabContainer = this.tabContainers[newIndex];
					const oldTabContainer = this.tabContainers[oldIndex];

					

					newTabContainer.style.display = 'block';
					const newHeight = newTabContainer.clientHeight;
					newTabContainer.style.display = 'none';

					var focusfix = $(newTabContainer).children().children().children()[0]

					

					TweenMax.to(oldTabContainer, 0.45, { opacity: 0, ease: Sine.easeOut });
					TweenMax.to(this.tabContainerWrapper, 0.45, {
						height: newHeight,
						ease: Power1.easeOut,
						onComplete: () => {
							oldTabContainer.style.display = 'none';
							newTabContainer.style.display = 'block';
							TweenMax.to(newTabContainer, 0.75, { opacity: 1, ease: Sine.easeOut });
							TweenMax.set(this.tabContainerWrapper, { height: 'auto' });
						}
					});

					window.setTimeout(function () {
						$(focusfix).focus()
					}, 800); 

					this.setState({ selectedIndex: newIndex });

					
					
				}
			}
		}
	};

	render() {
		return (
			<ViewableMonitor>
				<div className={`module-margin ${styles.wrapper}`}>
					<ul className={styles.tabList}>
						{this.props.data.moduleWrappers.map((moduleWrapper, index) => {
							const activeClass = this.state.selectedIndex === index ? ' ' + styles.active : '';
							return (
								// @ts-ignore
								<li onKeyDown={this.clickedTabItem} onClick={this.clickedTabItem} tabIndex={0} data-index={index} className={styles.tabItem + activeClass} key={moduleWrapper.id}>
									{moduleWrapper.titleForBackendOverviewPurposesOnly}
								</li>
							);
						})}
					</ul>
					<div ref={ref => (this.tabContainerWrapper = ref)} className={styles.tabContainersWrapper + ' module-margin-small'}>
						{this.props.data.moduleWrappers.map((moduleWrapper, index) => {
							return (
								<div ref={ref => (this.tabContainers[index] = ref)} key={moduleWrapper.id} className={styles.tabContainer}>
									<ModulesWrapper
										languageCode={this.props.languageCode}
										programsListData={this.props.programsListData}
										upcomingEventsData={this.props.upcomingEventsData}
										className={'nested-container'}
										data={[moduleWrapper as ContentfulModuleWrapperFragment]}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</ViewableMonitor>
		);
	}
}

export default ModuleTabContainer;

export const query = graphql`
	fragment ContentfulModuleTabContainerFragment on ContentfulModuleTabContainer {
		id
		moduleWrappers {
			id
			titleForBackendOverviewPurposesOnly
			modules {
				__typename
				...ContentfulModuleSectionIntroductionFragment
				...ContentfulModuleBodyTextFragment
				...ContentfulModuleQuoteFragment
				...ContentfulModuleFaqListFragment
				...ContentfulModuleVideoEmbeddedFragment
				...ContentfulModuleVideoYouTubeFragment
				...ContentfulModuleImageGalleryFragment
				...ContentfulModuleImageBodyFragment
				...ContentfulModule2ImagesFragment
				...ContentfulModuleMediaDownloadListFragment
				...ContentfulModuleStatisticFragment
				...ContentfulModuleTextImageHighlightFragment
				...ContentfulModuleOneColumnTextFragment
				...ContentfulModulePinpointListFragment
				...ContentfulModuleSectionTitleDividerFragment
				...ContentfulModuleHighlightedPersonasFragment
				...ContentfulModuleHighlightedItemsFragment
				...ContentfulModuleUpcomingEventsFragment
				...ContentfulModuleSideScrollerStoryListFragment
				...ContentfulModuleHighlightedEntitiesFragment
				...ContentfulModuleUpcomingEventsFragment
				...ContentfulModule2FeaturedPageLinksFragment
				...ContentfulModuleFeaturedPageLinkFragment
				...ContentfulModuleCtaLinkFragment
			}
		}
	}
`;
