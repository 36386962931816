import * as React from 'react';
import { Component } from 'react';
import * as styles from './ModuleFaqList.module.scss';
import { ContentfulModuleFaqListFragment, ContentfulModuleFaqListFragment_questions } from '../../gatsby-queries';
import { FormattedMessage } from 'react-intl';
import ViewableMonitor from '../ui/ViewableMonitor';
import { graphql } from 'gatsby';
import $ from 'jquery/dist/jquery.min';

const initialState = { open: false };
type State = Readonly<typeof initialState>;

class FaqListItem extends Component<{ data: ContentfulModuleFaqListFragment_questions }, State> {
	readonly state: State = initialState;

	private wrapper: HTMLDivElement;
	private animationWrapper: HTMLDivElement;

	private transitioning = false;

	private listItemClickHandler = event => {
		if (event) {
			event.preventDefault();
		}
		if (!this.state.open) {
			this.transitioning = true;
			this.animationWrapper.addEventListener('transitionend', this.transitionEnd);
			this.animationWrapper.style.height = this.wrapper.clientHeight + 'px';
		} else {
			if (!this.transitioning) {
				this.animationWrapper.style.height = this.wrapper.clientHeight + 'px';
				this.animationWrapper.offsetTop; //Render hack:
			}
			this.transitioning = true;
			this.animationWrapper.removeEventListener('transitionend', this.transitionEnd);
			this.animationWrapper.style.height = '0px';
		}
		this.setState({ open: !this.state.open });
	};

	private transitionEnd = () => {
		if (this.state.open) {
			this.transitioning = false;
			this.animationWrapper.removeEventListener('transitionend', this.transitionEnd);
			this.animationWrapper.style.height = 'auto';
		}
	};

	//private faqtabbingfix = (event) => {
	//if (event.key == 'Enter') {
	//this.listItemClickHandler(event)
	//}
	//};

	private faqqatabbingfix = event => {
		var answer = event.currentTarget;
		var parent = $(answer).parent()[0];
		var theanswer = $(parent)
			.next()
			.children()
			.children()[1];
		var check = $(theanswer).attr('tabindex');
		if (event.key == 'Enter') {
			this.listItemClickHandler(event);
			if (check == -1) {
				$(theanswer).attr('tabindex', 0);
				$(theanswer).focus();
			} else if (check == 0) {
				$(theanswer).attr('tabindex', -1);
			}
		}
	};

	render() {
		return (
			<li onClick={this.listItemClickHandler} className={`${styles.listItem} ${this.state.open ? styles.open : ''}`} key={this.props.data.id}>
				<div className={styles.questionHeadline}>
					<span className={styles.label}>
						<FormattedMessage id={'Q.'} />
					</span>
					<span className={styles.question} tabIndex={0} onKeyDown={this.faqqatabbingfix}>
						{this.props.data.question.question}
					</span>
					<div className={styles.toggleIcon} />
				</div>
				<div ref={ref => (this.animationWrapper = ref)} className={styles.answerWrapper}>
					<div className={styles.answerWrapperInner} ref={ref => (this.wrapper = ref)}>
						<span className={styles.label}>
							<FormattedMessage id={'A.'} />
						</span>
						<div
							className={`text-style-body-module ${styles.answer}` + ' ModuleFaqListanswerspan'}
							dangerouslySetInnerHTML={{ __html: this.props.data.answer.childMarkdownRemark.html }}
							tabIndex={-1}
						/>
					</div>
				</div>
			</li>
		);
	}
}

class ModuleFaqList extends Component<{ data: ContentfulModuleFaqListFragment }> {
	render() {
		return (
			<ViewableMonitor>
				<div className={`module-margin ${styles.wrapper}`}>
					<ul className={styles.listWrapper}>
						{this.props.data.questions.map(question => (
							<FaqListItem key={question.id} data={question} />
						))}
					</ul>
				</div>
			</ViewableMonitor>
		);
	}
}

export default ModuleFaqList;

export const query = graphql`
	fragment ContentfulModuleFaqListFragment on ContentfulModuleFaqList {
		id
		questions {
			id
			question {
				question
			}
			answer {
				answer
				childMarkdownRemark {
					html
				}
			}
		}
	}
`;
