import * as React from 'react';
import { Component } from 'react';
import ModuleMultipleQuotes from './ContentfulModules/ModuleMultipleQuotes';
import ModuleProcess from './ContentfulModules/ModuleProcess';
import ModuleForm from './ContentfulModules/ModuleForm';
import * as styles from './ModulesWrapper.module.scss';
import ModuleSectionIntroduction from './ContentfulModules/ModuleSectionIntroduction';
import ModuleBodyText from './ContentfulModules/ModuleBodyText';
import ModuleQuote from './ContentfulModules/ModuleQuote';
import ModuleFaqList from './ContentfulModules/ModuleFaqList';
import ModuleMediaList from './ContentfulModules/ModuleMediaList';
import ModuleImageGrid from './ContentfulModules/ModuleImageGrid';
import ModuleVideoEmbedded from './ContentfulModules/ModuleVideoEmbedded';
import ModuleVideoYouTube from './ContentfulModules/ModuleVideoYouTube';
import ModuleImageGallery from './ContentfulModules/ModuleImageGallery';
import ModuleImageBody from './ContentfulModules/ModuleImageBody';
import Module2Images from './ContentfulModules/Module2Images';
import Module4Columns from './ContentfulModules/Module4Columns';
import ModuleMediaDownloadList from './ContentfulModules/ModuleMediaDownloadList';
import ModuleStatistic from './ContentfulModules/ModuleStatistic';
import ModuleTextImageHighlight from './ContentfulModules/ModuleTextImageHighlight';
import ModuleOneColumnText from './ContentfulModules/ModuleOneColumnText';
import ModulePinpointList from './ContentfulModules/ModulePinpointList';
import { ContentfulEventPreviewFragment, ContentfulModuleWrapperFragment, PageVerticalQuery_allContentfulPageProgram } from '../gatsby-queries';
import { ModuleSectionTitleDivider } from './ContentfulModules/ModuleSectionTitleDivider';
import ModuleTabContainer from './ContentfulModules/ModuleTabContainer';
import ModuleHighlightedPersonas from './ContentfulModules/ModuleHighlightedPersonas';
import { ModuleHighlightedItems } from './ContentfulModules/ModuleHighlightedItems';
import { ModuleUpcomingEvents } from './ContentfulModules/ModuleUpcomingEvents';
import ModuleSideScrollerStoryList from './ContentfulModules/ModuleSideScrollerStoryList';
import { ModuleHighlightedEntities } from './ContentfulModules/ModuleHighlightedEntities';
import { ModuleProgramsList } from './ContentfulModules/ModuleProgramsList';
import Module2FeaturedPageLinks from './ContentfulModules/Module2FeaturedPageLinks';
import ModuleFeaturedPageLink from './ContentfulModules/ModuleFeaturedPageLink';
import ModuleCtaLink from './ContentfulModules/ModuleCtaLink';
import ModuleHomeNewsletter from './ContentfulModules/ModuleHomeNewsletter';
import ModuleShortBoxedText from './ContentfulModules/ModuleShortBoxedText';
import ModuleAccordion from './ContentfulModules/ModuleAccordion';
import ModuleBulletPointList from './ContentfulModules/ModuleBulletPointList';
import ModuleImageCompareSlider from './ContentfulModules/ModuleImageCompareSlider';
import { graphql } from 'gatsby';
import MediaGallery from './ContentfulModules/MediaGallery';
import ModuleRichBodyText from './ContentfulModules/ModuleRichBodyText';
import ModuleStatistics from './ContentfulModules/ModuleStatistics';
import ModuleRecentMediaGalleriesLogos from './ContentfulModules/ModuleRecentMediaGalleriesLogos';
import ModuleHeadingSummaryWithButtons from './ContentfulModules/ModuleHeadingSummaryWithButtons';
import ModuleMediaNumberedTextList from './ContentfulModules/ModuleMediaNumberedTextList';

class ModulesWrapper extends Component<{
	upcomingEventsData: ContentfulEventPreviewFragment[];
	programsListData?: PageVerticalQuery_allContentfulPageProgram;
	childrenLast?: boolean;
	data: ContentfulModuleWrapperFragment[];
	hasHeroImage?: boolean;
	className?: string;
	languageCode: 'en-US' | 'ar-QA';
	slug?: any;
}> {
	public render() {
		let sectionIndex = 0;
		let currModuleTextImageHighlightIndex = 0;
		const animationDirection = this.props.languageCode === 'ar-QA' ? -1 : 1;
		let modules = [];
		if (this.props.data) {
			this.props.data.forEach(function(moduleWrapper) {
				modules = modules.concat(moduleWrapper.modules);
			});
		}

		return (
			<div id={modules[0] ? modules[0].id : null} className={`container no-gutters ${this.props.hasHeroImage ? styles.hasHeroImage : ''} ${this.props.className ? this.props.className : ''}`}>
				{!this.props.childrenLast && this.props.slug !== 'media-center' && this.props.children}
				{modules.map((module, index) => {
					const id = module && module.id + index;
					if (module && module.__typename !== 'ContentfulModuleTextImageHighlight') {
						currModuleTextImageHighlightIndex = 0;
					} else {
						currModuleTextImageHighlightIndex++;
					}
					//@ts-ignore:
					if (this.props.slug === 'media-center' && this.props.children && this.props.children.props && this.props.children.props.position === index) {
						return (
							<React.Fragment>
								{this.props.children} <ModuleCtaLink key={id} data={module} />
							</React.Fragment>
						);
					}
					switch (module && module.__typename) {
						case 'ContentfulModuleSectionIntroduction':
							!module.hideSectionNumber && sectionIndex++;
							return <ModuleSectionIntroduction offsetForHeroImage={index === 0 && this.props.hasHeroImage ? true : false} sectionNumber={sectionIndex} key={id} data={module} />;
						case 'ContentfulModuleBodyText':
							return <ModuleBodyText key={id} data={module} />;
						case 'ContentfulModuleRichBodyText':
							return <ModuleRichBodyText key={id} data={module} />;
						case 'ContentfulModuleQuote':
							return <ModuleQuote key={id} data={module} />;
						case 'ContentfulModuleFaqList':
							return <ModuleFaqList key={id} data={module} />;
						case 'ContentfulModuleMediaList':
							return <ModuleMediaList key={id} data={module} />;
						case 'ContentfulModuleImageGrid':
							return <ModuleImageGrid key={id} data={module} />;
						case 'ContentfulModuleVideoEmbedded':
							return <ModuleVideoEmbedded key={id} data={module} />;
						case 'ContentfulModuleVideoYouTube':
							return <ModuleVideoYouTube key={id} data={module} />;
						case 'ContentfulModuleImageGallery':
							return <ModuleImageGallery animationDirection={animationDirection} key={id} data={module} />;
						case 'ContentfulModuleImageBody':
							return <ModuleImageBody key={id} data={module} />;
						case 'ContentfulModule2Images':
							return <Module2Images key={id} data={module} />;
						case 'ContentfulModule4Columns':
							return <Module4Columns key={id} data={module} />;
						case 'ContentfulModule2FeaturedPageLinks':
							return <Module2FeaturedPageLinks key={id} data={module} />;
						case 'ContentfulModuleMediaDownloadList':
							return <ModuleMediaDownloadList key={id} data={module} />;
						case 'ContentfulModuleStatistic':
							return <ModuleStatistic key={id} data={module} />;
						case 'ContentfulModuleStatisticS':
							return <ModuleStatistics key={id} data={module} />;
						case 'ContentfulModuleRecentMediaGalleriesLogos':
							return <ModuleRecentMediaGalleriesLogos key={id} data={module} />;
						case 'ContentfulModuleTextImageHighlight':
							return <ModuleTextImageHighlight key={id} data={module} index={currModuleTextImageHighlightIndex} />;
						case 'ContentfulModuleOneColumnText':
							return <ModuleOneColumnText key={id} data={module} />;
						case 'ContentfulModuleCtaLink':
							return <ModuleCtaLink key={id} data={module} />;
						case 'ContentfulModulePinpointList':
							return <ModulePinpointList key={id} data={module} />;
						case 'ContentfulModuleSectionTitleDivider':
							return <ModuleSectionTitleDivider key={id} data={module} />;
						case 'ContentfulModuleTabContainer':
							return (
								<ModuleTabContainer
									languageCode={this.props.languageCode}
									key={id}
									data={module}
									programsListData={this.props.programsListData}
									upcomingEventsData={this.props.upcomingEventsData}
								/>
							);
						case 'ContentfulModuleHighlightedPersonas':
							return <ModuleHighlightedPersonas key={id} data={module} currentSlug={this.props.slug} />;
						case 'ContentfulModuleHighlightedItems':
							return <ModuleHighlightedItems key={id} data={module} />;
						case 'ContentfulModuleSideScrollerStoryList':
							return <ModuleSideScrollerStoryList animationDirection={animationDirection} key={id} data={module} />;
						case 'ContentfulModuleFeaturedPageLink':
							return <ModuleFeaturedPageLink key={id} data={module} />;
						case 'ContentfulModuleHighlightedEntities':
							return <ModuleHighlightedEntities animationDirection={animationDirection} key={id} data={module} />;
						case 'ContentfulModuleUpcomingEvents':
							return <ModuleUpcomingEvents animationDirection={animationDirection} events={this.props.upcomingEventsData} key={id} data={module} />;
						case 'ContentfulModuleProgramsList':
							return this.props.programsListData ? <ModuleProgramsList programs={this.props.programsListData} key={id} data={module} /> : undefined;
						case 'ContentfulModuleNewsletterSignup':
							return <ModuleHomeNewsletter data={module} />;
						case 'ContentfulModuleShortBoxedText':
							return <ModuleShortBoxedText key={id} data={module} />;
						case 'ContentfulModuleAccordion':
							return <ModuleAccordion key={id} data={module} />;
						case 'ContentfulModuleBulletPointList':
							return <ModuleBulletPointList key={id} data={module} />;
						case 'ContentfulModuleProcess':
							return <ModuleProcess key={id} data={module} />;
						case 'ContentfulModuleHeadingSummaryWithButtons':
							return <ModuleHeadingSummaryWithButtons key={id} data={module} />;
						case 'ContentfulModuleMultipleQuotes':
							return <ModuleMultipleQuotes key={id} data={module} />
						case 'ContentfulModuleMediaNumberedTextList':
							return <ModuleMediaNumberedTextList key={id} data={module} />
						case 'ContentfulModuleForm':
							return <ModuleForm key={id} data={module} />;
						case 'ContentfulMediaGallery':
							return <MediaGallery key={id} data={module} />;
						case 'ContentfulModuleImageCompareSlider':
							return <ModuleImageCompareSlider key={id} data={module} />;	
					}
				})}
				{this.props.childrenLast && this.props.slug !== 'media-center' && this.props.children}
			</div>
		);
	}
}

export default ModulesWrapper;

export const query = graphql`
	fragment ContentfulModuleWrapperFragment on ContentfulModuleWrapper {
		modules {
			__typename
			...ContentfulModuleSectionIntroductionFragment
			...ContentfulModuleBodyTextFragment
			...ContentfulModuleQuoteFragment
			...ContentfulModuleFaqListFragment
			...ContentfulModuleMediaListFragment
			...ContentfulModuleImageGridFragment
			...ContentfulModuleVideoEmbeddedFragment
			...ContentfulModuleVideoYouTubeFragment
			...ContentfulModuleImageGalleryFragment
			...ContentfulModuleImageBodyFragment
			...ContentfulModule2ImagesFragment
			...ContentfulModule4ColumnsFragment
			...ContentfulModuleMediaDownloadListFragment
			...ContentfulModuleStatisticFragment
			...ContentfulModuleStatisticsFragment
			...ContentfulModuleTextImageHighlightFragment
			...ContentfulModuleOneColumnTextFragment
			...ContentfulModulePinpointListFragment
			...ContentfulModuleSectionTitleDividerFragment
			...ContentfulModuleTabContainerFragment
			...ContentfulModuleHighlightedPersonasFragment
			...ContentfulModuleHighlightedItemsFragment
			...ContentfulModuleUpcomingEventsFragment
			...ContentfulModuleSideScrollerStoryListFragment
			...ContentfulModuleHighlightedEntitiesFragment
			...ContentfulModuleUpcomingEventsFragment
			...ContentfulModule2FeaturedPageLinksFragment
			...ContentfulModuleFeaturedPageLinkFragment
			...ContentfulModuleCtaLinkFragment
			...ContentfulModuleShortBoxedTextFragment
			...ContentfulModuleAccordionFragment
			...ContentfulModuleBulletPointListFragment
			...ContentfulModuleProcessFragment
			...ContentfulModuleMultipleQuotesFragment
			...ContentfulModuleFormFragment
			...ContentfulMediaGalleryFragment
			...ContentfulModuleRichBodyTextFragment
			...ContentfulModuleRecentMediaGalleriesLogosFragment
			...ContentfulModuleHeadingSummaryWithButtonsFragment
			...ContentfulModuleMediaNumberedTextListFragment
			...ContentfulModuleImageCompareSliderFragment
		}
	}
`;
